<template>
  <v-card flat>
      <v-toolbar flat color="info">
            <v-toolbar-title>
                404
            </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
            <v-row>
                <v-col cols='12' class="title text-center">
                    <p class="display-3">
                        Page Not Found!
                    </p>
                    <p>
                        Sorry, can't find what you're looking for. Go back and try again.
                    </p>
                    <p>
                        If you still have issues please let the WLD admins know <a href="https://discord.gg/0h2DOuBCUWs9XHZp" target="_blank" class="secondary--text">on Discord</a>.
                    </p>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
}
</script>